<template>
    <div class="l-stack">
        <div class="l-stack u-bb">
            <HiddenForm
                class="u-bb"
                :hidden="isCustomFormHidden"
                @toggle="isCustomFormHidden = $event"
            >
                <template #title>
                    {{ $t('customFormTitle') }}
                </template>

                <template #form>
                    <div class="l-stack l-gap-1 l-padded">
                        <DateRangeInput
                            :value="dateRange"
                            @input="handleDateRangeChange"
                        />
                    </div>
                </template>
            </HiddenForm>

            <HiddenForm
                :hidden="isPredefinedFormHidden"
                @toggle="isPredefinedFormHidden = $event"
            >
                <template #title>
                    {{ $t('predefinedFormTitle') }}
                </template>

                <template #form>
                    <div class="l-stack l-gap-1 l-padded">
                        <AxButton
                            v-for="(option, key, index) in dateOptions"
                            :key="index"
                            @click="handleDateRangeChange(option)"
                        >
                            {{ $t(key) }}
                        </AxButton>
                    </div>
                </template>
            </HiddenForm>
        </div>

        <div
            v-if="isTripModePossible"
            class="l-padded l-inline l-spread l-center-v u-bb"
        >
            <span>{{ $t('showTrips') }}</span>

            <ToggleButton
                :value="isTripMode"
                @input="
                    $router.push({
                        name: isTripMode ? 'locationhistory' : 'triphistory',
                        params: { id, isTripModePossible },
                    })
                "
            />
        </div>

        <div
            v-if="isTripMode"
            class="l-padded l-inline l-gap-2 l-spread l-center-v u-bb"
        >
            <span>{{ $t('expandTrips') }}</span>

            <IconButton @click="isTripsExpanded = !isTripsExpanded">
                <ExpandDiagonalIcon width="18" height="18" />
            </IconButton>
        </div>

        <div class="l-padded l-stack l-gap-2 u-bb">
            <div class="l-inline l-spread l-center-v">
                <span>{{ $t('showMeasurements') }}</span>

                <ToggleButton v-model="isMeasurementsVisible" />
            </div>

            <BaseMultiselect
                v-if="isMeasurementsVisible"
                v-model="selectedMeasurement"
                :options="availableMeasurements"
                :custom-label="
                    option =>
                        $root.$te(`shared.measurements.${option}`)
                            ? $t(`shared.measurements.${option}`)
                            : option
                "
                :taggable="isSuperuser"
                @tag="selectedMeasurement = $event"
            />
        </div>

        <List
            v-infinite-scroll="loadMoreListData"
            :is-loading="isTripMode ? isLoadingTrips : isLoadingHistory"
            infinite-scroll-distance="900"
        >
            <template v-if="isTripMode">
                <div v-for="(trip, index) in listDataPortion" :key="trip.id">
                    <TripListItem
                        :trip="trip"
                        :selected="selectedTrip && selectedTrip.id === trip.id"
                        :class="'historyItem-' + trip.id"
                        @click="handleListItemClick"
                    />

                    <TripPauseListItem
                        v-if="listDataPortion[index + 1]"
                        :trip-from="trip"
                        :trip-to="listDataPortion[index + 1]"
                    />
                </div>
            </template>

            <template v-else>
                <AssetLocationHistoryItem
                    v-for="location in listDataPortion"
                    :key="location.id"
                    :location="location"
                    :selected="assetHistoryLocationId == location.id"
                    :class="'historyItem-' + location.id"
                    @click="handleListItemClick"
                />
            </template>
        </List>

        <transition name="slide-from-right">
            <div v-if="isTripMode && isTripsExpanded" class="detached-panel">
                <div
                    class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                >
                    {{ $t('expandedTrips') }}

                    <div class="l-inline l-gap-2">
                        <IconButton
                            :title="$t('export')"
                            @click="handleDownload"
                        >
                            <FileDownloadIcon width="22" height="22" />
                        </IconButton>

                        <IconButton @click="isTripsExpanded = false">
                            <RemoveIcon width="24" height="24" color="black" />
                        </IconButton>
                    </div>
                </div>

                <div class="detached-panel-body">
                    <div
                        v-if="!listData.length"
                        class="l-padded l-inline l-center"
                    >
                        <VSpinner
                            v-if="isLoadingTrips"
                            size="medium"
                            line-fg-color="#000"
                            :speed="1"
                        />

                        <span v-else>
                            {{ $t('noResults') }}
                        </span>
                    </div>

                    <table
                        v-else
                        v-infinite-scroll="loadMoreListData"
                        infinite-scroll-distance="900"
                    >
                        <tr>
                            <th>{{ $t('tableStartTime') }}</th>
                            <th>{{ $t('tableDuration') }}</th>
                            <th>{{ $t('tableEndTime') }}</th>
                            <th>{{ $t('tableAddressStart') }}</th>
                            <th>{{ $t('tableAddressEnd') }}</th>
                            <th>{{ $t('tableDistance') }}</th>
                            <th>{{ $t('tableOdometer') }}</th>
                            <th>{{ $t('tableRunningTime') }}</th>
                            <th>{{ $t('tableDriver') }}</th>
                            <th>{{ $t('tableTrailers') }}</th>
                        </tr>

                        <tr v-for="item in listDataPortion" :key="item.id">
                            <td>
                                {{
                                    formatTimestamp(
                                        item.first_measurement.timestamp
                                    )
                                }}
                            </td>

                            <td>
                                {{
                                    formatTripDuration(
                                        item.first_measurement.timestamp,
                                        item.last_measurement.timestamp
                                    )
                                }}
                            </td>

                            <td>
                                {{
                                    formatTimestamp(
                                        item.last_measurement.timestamp
                                    )
                                }}
                            </td>

                            <td>
                                <a
                                    class="u-bb clickable"
                                    @click="
                                        showOnMap(
                                            item.first_measurement.lat,
                                            item.first_measurement.lng
                                        )
                                    "
                                >
                                    {{ item.start_address }}
                                </a>
                            </td>

                            <td>
                                <a
                                    class="u-bb clickable"
                                    @click="
                                        showOnMap(
                                            item.last_measurement.lat,
                                            item.last_measurement.lng
                                        )
                                    "
                                >
                                    {{ item.end_address }}
                                </a>
                            </td>

                            <td>
                                {{ formatDistance(item.trip_distance) }}
                            </td>

                            <td>
                                {{ formatOdometer(item.last_odometer_value) }}
                            </td>

                            <td>
                                {{
                                    formatRunningTime(
                                        item.last_measurement.sensor_data
                                            .running_time
                                    )
                                }}
                            </td>

                            <td>
                                {{ item.driver_name }}
                                <template v-if="item.driver_id">
                                    ({{ item.driver_id }})
                                </template>
                            </td>

                            <td>
                                {{ getTrailers(item) }}
                            </td>
                        </tr>
                    </table>

                    <div
                        v-if="listDataPortion.length < listData.length"
                        class="l-padded l-inline l-center table-footer"
                    >
                        <BaseButton size="small" @click="loadMoreListData">
                            {{ $t('showMore') }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="slide-from-bottom">
            <div
                v-if="isMeasurementsVisible && selectedMeasurement"
                class="detached-panel detached-panel--bottom"
            >
                <AssetLocationHistoryMeasurementsChart
                    :id="id"
                    :measurement="selectedMeasurement"
                    :history-points="chartHistoryPoints"
                    :is-loading="isLoadingHistory || isLoadingTrips"
                    @pointChange="handleHistoryPointChange"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment-timezone'
import infiniteScroll from 'vue-infinite-scroll'
import VSpinner from 'vue-simple-spinner'

import { domHelper, formatHelper, measurementHelper } from '@/utils'
import AssetLocationHistoryItem from '@/components/AssetLocationHistoryItem'
import AssetLocationHistoryMeasurementsChart from '@/components/AssetLocationHistoryMeasurementsChart'
import AxButton from '@/components/AxButton'
import BaseButton from '@/components/redesigned/BaseButton'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import DateRangeInput from '@/components/DateRangeInput'
import ExpandDiagonalIcon from '@/components/icons/ExpandDiagonalIcon'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon'
import HiddenForm from '@/components/HiddenForm'
import IconButton from '@/components/IconButton'
import InfinityScrollHelper from '@/mixins/InfinityScrollHelper'
import List from '@/components/List'
import RemoveIcon from '@/components/icons/RemoveIcon'
import ToggleButton from '@/components/ToggleButton'
import TripListItem from '@/components/TripListItem'
import TripPauseListItem from '@/components/TripPauseListItem'

const exportDatetimeFormat = 'YYYY-MM-DD HH:mm:ss'

export default {
    name: 'AssetLocationHistory',
    components: {
        AssetLocationHistoryItem,
        AssetLocationHistoryMeasurementsChart,
        AxButton,
        BaseButton,
        BaseMultiselect,
        DateRangeInput,
        ExpandDiagonalIcon,
        FileDownloadIcon,
        HiddenForm,
        IconButton,
        List,
        RemoveIcon,
        ToggleButton,
        TripListItem,
        TripPauseListItem,
        VSpinner,
    },
    directives: {
        infiniteScroll,
    },
    mixins: [InfinityScrollHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        isTripModePossible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dateOptions: {
                today: {
                    startDate: moment().startOf('day'),
                    endDate: moment().endOf('day'),
                },
                yesterday: {
                    startDate: moment()
                        .subtract(1, 'days')
                        .startOf('day'),
                    endDate: moment()
                        .subtract(1, 'days')
                        .endOf('day'),
                },
                thisMonth: {
                    startDate: moment().startOf('month'),
                    endDate: moment().endOf('month'),
                },
                lastMonth: {
                    startDate: moment()
                        .subtract(1, 'month')
                        .startOf('month'),
                    endDate: moment()
                        .subtract(1, 'month')
                        .endOf('month'),
                },
            },
            dateRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isCustomFormHidden: false,
            isLoadingHistory: false,
            isMeasurementsVisible: false,
            isPredefinedFormHidden: true,
            isTripsExpanded: false,
            selectedMeasurement: null,
        }
    },
    computed: {
        ...mapState('map', ['mapInstance']),
        ...mapState('tracker', [
            'assetHistory',
            'assetHistoryLocationId',
            'trackers',
        ]),
        ...mapState('trip', ['isLoadingTrips', 'trips']),
        ...mapGetters('auth', ['isSuperuser']),
        ...mapGetters('trip', ['selectedTrip']),
        assetId() {
            return this.tracker?.asset
        },
        availableMeasurements() {
            return Object.keys(this.tracker.asset_details.sensor_data).sort()
        },
        chartHistoryPoints() {
            if (!this.listData?.length) {
                return []
            }

            if (this.isTripMode) {
                if (this.selectedTrip) {
                    return this.selectedTrip.measurements.map(measurement => ({
                        timestamp: measurement.timestamp,
                        position: [measurement.lat, measurement.lng],
                    }))
                }

                return this.listData.reduce((acc, cur) => {
                    return [
                        ...acc,
                        ...cur.measurements.map(measurement => ({
                            timestamp: measurement.timestamp,
                            position: [measurement.lat, measurement.lng],
                        })),
                    ]
                }, [])
            }

            return [...this.listData].reverse().map(item => ({
                timestamp: item.timestamp,
                position: [item.lat, item.lng],
            }))
        },
        isTripMode() {
            return this.$route.name === 'triphistory'
        },
        listData() {
            return this.isTripMode ? this.trips : this.assetHistory
        },
        tracker() {
            return this.trackers.find(tracker => tracker.id == this.id)
        },
    },
    watch: {
        assetHistoryLocationId() {
            this.setAssetHistoryPoint(null)
            this.scrollToHistoryItem(this.assetHistoryLocationId)
        },
        dateRange() {
            this.setAssetHistoryPoint(null)
            this.load()
        },
        selectedTrip() {
            this.setAssetHistoryPoint(null)
            if (this.selectedTrip) {
                this.scrollToHistoryItem(this.selectedTrip.id)
            }
        },
        isTripMode() {
            this.setAssetHistoryPoint(null)
            this.load()
        },
        isMeasurementsVisible() {
            this.setAssetHistoryPoint(null)
        },
        selectedMeasurement() {
            this.setAssetHistoryPoint(null)
        },
    },
    beforeRouteLeave(to, from, next) {
        this.resetAssetHistory()
        this.setAssetHistoryPoint(null)
        next()
    },
    methods: {
        ...mapActions('tracker', ['loadHistoryData']),
        ...mapActions('trip', ['loadTrips']),
        ...mapMutations('tracker', [
            'resetAssetHistory',
            'setAssetHistoryLocationId',
            'setAssetHistoryPoint',
        ]),
        ...mapMutations('trip', ['setSelectedTripId']),
        formatTimestamp(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm')
        },
        handleDateRangeChange(dates) {
            this.dateRange = {
                startDate: moment(dates.startDate).toDate(),
                endDate: moment(dates.endDate).toDate(),
            }
        },
        handleHistoryPointChange(point) {
            if (
                !point ||
                typeof point.position[0] !== 'number' ||
                typeof point.position[1] !== 'number'
            ) {
                this.setAssetHistoryPoint(null)
                return
            }

            this.setAssetHistoryPoint(point.position)

            if (!this.mapInstance.getBounds().contains(point.position)) {
                this.mapInstance.flyToBounds([
                    point.position,
                    ...this.chartHistoryPoints.map(({ position }) => position),
                ])
            }
        },
        formatDistance(value) {
            if (typeof value !== 'number') {
                return ''
            }

            return value >= 1000
                ? `${(value / 1000).toFixed(2)}km`
                : `${value}m`
        },
        formatOdometer(value) {
            return typeof value === 'number'
                ? `${measurementHelper.converters.odometer(value)}km`
                : ''
        },
        formatRunningTime(value) {
            return typeof value === 'number'
                ? formatHelper.hoursAndMinutesDuration(value)
                : ''
        },
        getTrailers(item) {
            return item.trailers
                .map(item => `${item.name} (${item.percentage}%)`)
                .join(',')
        },

        formatTripDuration(startDate, endDate) {
            const timeDiff = moment(endDate).diff(moment(startDate))
            const tripDuration = moment.duration(timeDiff)
            return tripDuration.format('h[h] mm[min] ss[s]', {
                usePlural: false,
            })
        },
        handleDownload() {
            const rows = [
                [
                    'datetime_start',
                    'duration (s)',
                    'datetime_end',
                    'address_start',
                    'address_end',
                    'distance (m)',
                    'odometer (km)',
                    'running_time (s)',
                    'driver_name',
                    'driver_id',
                    'lat_start',
                    'lng_start',
                    'lat_end',
                    'lng_end',
                ],
                ...this.listData.map(data => {
                    const startTime = moment(data.first_measurement.timestamp)
                    const endTime = moment(data.last_measurement.timestamp)

                    return [
                        startTime.format(exportDatetimeFormat),
                        endTime.diff(startTime) / 1000,
                        endTime.format(exportDatetimeFormat),
                        `"${data.start_address}"`,
                        `"${data.end_address}"`,
                        data.trip_distance,
                        Math.round(
                            data.last_measurement.sensor_data.odometer / 1000
                        ) || '',
                        data.last_measurement.sensor_data.running_time || '',
                        data.driver_name,
                        data.driver_id,
                        data.first_measurement.lat,
                        data.first_measurement.lng,
                        data.last_measurement.lat,
                        data.last_measurement.lng,
                    ]
                }),
            ]

            const dataUrl = `data:text/csv;charset=utf-8,\uFEFF${rows
                .map(row => row.join(','))
                .join('\n')}`

            const filename = `${this.$t('router.triphistory')}-${new Date()
                .toJSON()
                .slice(0, 19)
                .replace('T', '-')
                .replaceAll(':', '')}.csv`.replace(' ', '-')

            domHelper.downloadDataUrl(dataUrl, filename)
        },
        handleListItemClick({ id }) {
            if (this.isTripMode) {
                this.setSelectedTripId(this.selectedTrip?.id === id ? null : id)
            } else {
                this.setAssetHistoryLocationId(id)
            }
        },
        async load() {
            if (this.isTripMode) {
                this.setSelectedTripId(null)
                this.loadTrips({
                    assetId: this.assetId,
                    fromDate: moment(this.dateRange.startDate).format(),
                    toDate: moment(this.dateRange.endDate).format(),
                })
            } else {
                this.isLoadingHistory = true
                await this.loadHistoryData({
                    trackerId: this.id,
                    fromDate: moment(this.dateRange.startDate).format(),
                    toDate: moment(this.dateRange.endDate).format(),
                })
                this.isLoadingHistory = false
            }
        },
        scrollToHistoryItem(id) {
            const className = `historyItem-${id}`
            this.loadListDataUntilFound(className, () => {
                const [element] = this.$el.getElementsByClassName(className)
                if (element && !domHelper.isElementVisible(element)) {
                    element.scrollIntoView()
                }
            })
        },
        showOnMap(lat, lng) {
            this.mapInstance.flyTo([lat, lng], 20, { animate: false })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "showMeasurements": "Show measurements",
        "showTrips": "Show trips",
        "predefinedFormTitle": "Predefined date range",
        "customFormTitle": "Custom date range",
        "expandTrips": "Expand Trips",
        "expandedTrips": "Expanded Trips",
        "export": "CSV Export",
        "noResults": "No results",
        "showMore": "Show more",
        "tableAddressEnd": "End Address",
        "tableAddressStart": "Start Address",
        "tableDistance": "Distance",
        "tableRunningTime": "Running Hours",
        "tableOdometer": "Odometer",
        "tableDriver": "Driver",
        "tableTrailers": "Trailers",
        "tableDuration": "Duration",
        "tableEndTime": "End Time",
        "tableStartTime": "Start Time",
        "today": "Today",
        "yesterday": "Yesterday",
        "thisMonth": "This month",
        "lastMonth": "Last month",
        "thisYear": "This year",
        "lastYear": "Last year",
        "lastFiveyears": "Last 5 years"
    },
    "de": {
        "showMeasurements": "Messungen anzeigen",
        "showTrips": "Trips anzeigen",
        "predefinedFormTitle": "Vordefinierter Zeitraum",
        "customFormTitle": "Benutzerdefinierter Zeitraum",
        "expandTrips": "Detaillierte Fahrten",
        "expandedTrips": "Detaillierte Fahrten",
        "export": "CSV Export",
        "noResults": "Keine Ergebnisse",
        "showMore": "Mehr anzeigen",
        "tableAddressEnd": "Zieladresse",
        "tableAddressStart": "Startadresse",
        "tableDistance": "Distanz",
        "tableRunningTime": "Betriebsstunden",
        "tableOdometer": "Kilometerzähler",
        "tableDriver": "Fahrer",
        "tableTrailers": "Anhänger",
        "tableDuration": "Dauer",
        "tableEndTime": "Endzeit",
        "tableStartTime": "Startzeit",
        "today": "Heute",
        "yesterday": "Gestern",
        "thisMonth": "Diesen Monat",
        "lastMonth": "Letzer Monat",
        "thisYear": "Dieses Jahr",
        "lastYear": "Letztes Jahr",
        "lastFiveyears": "Letzte 5 Jahre"
    },
    "fr": {
        "showMeasurements": "Montrer les mesures",
        "showTrips": "Afficher les voyages",
        "predefinedFormTitle": "Plages de dates prédéfinie",
        "customFormTitle": "Plage de date personnalisée",
        "expandTrips": "Etendre les voyages",
        "expandedTrips": "Voyages étendus",
        "export": "Exporter CSV",
        "noResults": "Aucun résultat",
        "showMore": "Afficher plus",
        "tableAddressEnd": "Adresse de fin",
        "tableAddressStart": "Adresse de début",
        "tableDistance": "Distance",
        "tableRunningTime": "Heures de fonctionnement",
        "tableOdometer": "Compteur kilométrique",
        "tableDriver": "Conducteur",
        "tableTrailers": "Remorques",
        "tableDuration": "Durée",
        "tableEndTime": "Heure de fin",
        "tableStartTime": "Heure de début",
        "today": "Aujourd'hui",
        "yesterday": "Hier",
        "thisMonth": "Ce mois",
        "lastMonth": "Le dernier mois",
        "thisYear": "Cette année",
        "lastYear": "La dernière année",
        "lastFiveyears": "Les 5 derniers mois"
    },
    "it": {
        "showMeasurements": "Mostra le misure",
        "showTrips": "Visualizzare i viaggi",
        "predefinedFormTitle": "Intervallo di date predefinito",
        "customFormTitle": "Intervallo di date personalizzato",
        "expandTrips": "Espandere i viaggi",
        "expandedTrips": "Viaggi dettagliati",
        "export": "CSV Export",
        "noResults": "Nessun Risultato",
        "showMore": "Mostra altri",
        "tableAddressEnd": "Indirizzo finale",
        "tableAddressStart": "Indirizzo iniziale",
        "tableDistance": "Distanza",
        "tableRunningTime": "Ore di funzionamento",
        "tableOdometer": "Contachilometri",
        "tableDriver": "Conducente",
        "tableTrailers": "Rimorchi",
        "tableDuration": "Durata",
        "tableEndTime": "Ora di fine",
        "tableStartTime": "Ora di inizio",
        "today": "Oggi",
        "yesterday": "Ieri",
        "thisMonth": "Queso Mese",
        "lastMonth": "Lo scorso Mese",
        "thisYear": "Quest' anno",
        "lastYear": "L'anno scorso",
        "lastFiveyears": "Ultimi 5 Anni"
    }
}
</i18n>

<style lang="scss" scoped>
.detached-panel {
    &-body {
        overflow: auto;
    }

    &--bottom {
        top: auto;
        right: calc(375px + 1rem);
        bottom: 0;
        width: calc(100vw - 375px - 3rem);
        max-height: 50%;
        background-color: rgba(255, 255, 255, 0.75);
        overflow-x: hidden;

        @include respond-to('for-mobile-down') {
            width: 100%;
            min-width: 100%;
            right: 0;
            left: 0;
        }
    }
}

table {
    display: block;
    width: 100%;
    border-spacing: 0;

    tr {
        &:not(:last-child) {
            td {
                border-bottom: $style-border;
            }
        }

        th,
        td {
            &:not(:first-child) {
                border-left: $style-border;
            }
        }

        th {
            padding: 1rem;
            text-align: left;
            border-bottom: $style-border;
        }

        td {
            padding: 0.5rem 1rem;
        }

        @include respond-to('for-tablet-down') {
            &:first-child {
                position: sticky;
                top: 0;
                background-color: #fff;
                z-index: 10;
            }
        }
    }
}

.table-footer {
    position: sticky;
    left: 0;
    border-top: $style-border;
}
</style>
